angular.module('enervexSalesappApp').controller('CustomComponentCtrl', function($scope, $uibModalInstance, _, Product, component, VentMaterial, Fitting, Baffle, MetalType, InsulationType, ProductSubtype, Manufacturer, ProductType, Flash, Util) {
	$scope.errors = {};
	$scope.ctx = {
		formChanged: false
	}
	Product.fields().$promise.then(function(result){
		$scope.allFields = result
	})

	if (!component) {
		var objectId = new ObjectId();
		$scope.customComponent = {
			_id: ''+ objectId,
			shape: 'ROU',
			schedule: {
				notes: []
			},
			unit: 'EA',
			centerPointA: {
				shape:'ROU'
			},
			centerPointB: {
				shape:'ROU'
			},
			centerPointC: {
				shape:'ROU'
			},
			centerPointD: {
				shape:'ROU'
			}
		}
		fetchDependents()
	} else {
		$scope.customComponent = component;
		fetchDependents()
	}
	$scope.change = function() {
		$scope.ctx.formChanged = true;
	}
	$scope.productTypeChanged = function() {
		$scope.filterProductSubtypes()
	}
	function fetchDependents() {
		$scope.shapes = [
			"ROU",
			"SQU",
			"REC",
			"OVA"
		];
		$scope.styles = [
			"",
			"TAPER",
			"STEP",
		];
		$scope.couplerTypes = [
			"",
			"ECCENTRIC",
			"CONCENTRIC",
		];
		$scope.baffleVoltages = [
			"24",
			"120",
			"240"
		];

		$scope.voltages = _.map($scope.baffleVoltages, function(voltage) {
			var base = {value: voltage, selected: false}

			if (_.contains($scope.customComponent.voltages, voltage)) {
				base.selected = true;
			}

			return base
		})

		VentMaterial.query().$promise.then(function(ventMaterials){
			$scope.ventMaterials = _.map(ventMaterials, function(ventMaterial){
				var existing = _.find($scope.customComponent.ventMaterials, function(vm){
					return vm == ventMaterial._id
				})
				if (existing){
					ventMaterial.selected = true;
				}
				return ventMaterial
			})
		})
		Fitting.query({
			limit: 1000,
			active: true
		}).$promise.then(function(fittings){
			fittings = _.sortBy(fittings, function(f){
				return f.name
			})
			$scope.fittings = fittings;
		})
		Baffle.query().$promise.then(function(baffles){
			$scope.baffles = baffles;
			$scope.selectBaffle()
		})
		MetalType.query().$promise.then(function(result){
			$scope.metalTypes = result;
		})
		InsulationType.query().$promise.then(function(result){
			$scope.insulationTypes = result;
		})
		ProductSubtype.query().$promise.then(function(result){{
			$scope.allProductSubtypes = Util.sortUncased(result, "name")
			_.each($scope.allProductSubtypes, function(o){
				console.log("productSubtype: "+o.name)
			})

			$scope.filterProductSubtypes()
		}})
		Manufacturer.query().$promise.then(function(result){{
			$scope.manufacturers = result
			$scope.enervexManufacturer = _.find($scope.manufacturers, function(o){
				return o.isDefault
			})
			if (!component) {
				//default to enervex
				var manufacturer = _.find($scope.manufacturers, function(o){
					return o.isDefault
				})
				$scope.customComponent.manufacturer = manufacturer._id
			} else {
				if ($scope.customComponent.manufacturer) {
					$scope.customComponent.manufacturer = $scope.customComponent.manufacturer._id
				}
			}
		}})

	}
	$scope.selectedProductType = function() {
		return _.find($scope.productTypes, function(productType) {
			return productType._id == $scope.customComponent.type
		})
	}
	$scope.filterProductSubtypes = function() {
		var productType = $scope.selectedProductType()
		$scope.productSubtypes = _.filter($scope.allProductSubtypes,function(o){
			var has = _.find(o.productTypes, function(p){
				return p._id == (productType && productType._id)
			})
			return (has) ? true : false
		})
	}
	$scope.selectBaffle = function() {
		var baffle = _.find($scope.baffles, function(baffle){
			return baffle._id == $scope.customComponent.baffle && $scope.customComponent.baffle._id;
		})
		$scope.selectedBaffle= baffle;
	}
	ProductType.query().$promise.then(function(result){
		var productTypes = _.filter(result, function(o){
			return o.active
		})
		$scope.productTypes = _.sortBy(productTypes, function(o){
			return o.name.toLowerCase()
		})
	})
	$scope.hasProductTypeField = function(field){
		var productType = _.find($scope.productTypes, function(pt){
			return pt._id == ($scope.customComponent && $scope.customComponent.type)
		})
		if (productType) {
			var contains = _.find(productType.fields, function(_field){
				return _field == field
			})
			return contains ? true : false
		}
		return false
	}
	$scope.closeCustomComponentModal = function() {
		var modalInstance = $uibModalInstance.close();
	}
	$scope.saveCustomComponent = function() {
		Flash.clear();

		$scope.customComponent.ventMaterials = _.pluck(_.filter($scope.ventMaterials, function(a){
			return a.selected
		}), "_id")
		$scope.customComponent.voltages = _.pluck(_.filter($scope.voltages, function(a){
			return a.selected
		}), "value")
		if ($scope.customComponent.type == "") {
			$scope.customComponent = null
		}
		var modalInstance = $uibModalInstance.close($scope.customComponent);
	};
	$scope.deleteProduct = function() {
		$scope.design.customComponents = _.filter($scope.design.customComponents, function(o){
			return o._id != $scope.customComponent._id
		})
		var modalInstance = $uibModalInstance.close();
	}
});
